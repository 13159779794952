import App from 'App';
import SupportIrrContainer from 'containers/SupportIrrContainer';
import React from 'react';

const Farm: React.FC<{}> = () => {
  return (
    <App>
      <SupportIrrContainer />
    </App>
  );
};

export default Farm;
