/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable operator-linebreak */
import Image from 'common/components/Image';
import { Table } from 'common/models/table';
import { AppSyncFarm, MonitorVaults } from 'common/models/monitorVaults';
import { StyledFlexAlignCenter, StyledTable, StyledTableHeadingTr, StyledTableTh } from 'common/styles/common.styles';
import React from 'react';
import Tooltip from '../Tooltip/Tooltip';
import { TooltipWrapper } from '../Tooltip/tooltip.style';
import SupportIrrTablePosition from './SupportIrrTablePosition';
import { Pools } from '@ichidao/ichi-sdk/dist/src/constants/pools';
import { MonitoredAmm } from 'constants/constants';

type Props = {
  table: Table;
  farms: AppSyncFarm[];
  vaults: MonitorVaults[];
  filter: MonitoredAmm;
};

const SupportIrrTable: React.FC<Props> = (props) => {
  const getFarmByVault = (vault: MonitorVaults) =>{
    return props.farms.filter((f) => f.lpAddress.toLowerCase() === vault.address.toLowerCase())[0];
  }; 
  const filterCriteria = (v: MonitorVaults) => {
    const farm = getFarmByVault(v);
    if (!farm) {
      return false;
    }
    if (Pools.LEGACY_POOLS[farm.chainId]?.includes(farm.poolId)){
      return false;
    }
    return farm.chainId === props.filter.chain && v.displayName.includes(props.filter.indicator);
  };
  const vaults = [...props.vaults]
    .filter((v) => {return filterCriteria(v);})
    .sort((a, b) => { return b.name < a.name ? 1 : -1; });

  return (
    <>
      <StyledTable>
        <thead>
          <StyledTableHeadingTr className="table-border-bottom">
            {props.table?.headers?.map((header) => (
              <StyledTableTh key={header.name} className="sub-text-color">
                <StyledFlexAlignCenter>
                  <div className="mr-5">{header.name}</div>
                  {header.helpText && (
                    <TooltipWrapper>
                      <Tooltip message={header.helpText} position="top" bubbleSize={{ width: '200px' }}>
                        <Image className="icon-help" alt={header.helpText} />
                      </Tooltip>
                    </TooltipWrapper>
                  )}
                </StyledFlexAlignCenter>
              </StyledTableTh>
            ))}
          </StyledTableHeadingTr>
        </thead>
        <tbody>
          {vaults?.map((vault) => (
            <SupportIrrTablePosition key={vault.name} vault={vault} />
          ))}
        </tbody>
      </StyledTable>
    </>
  );
};

export default SupportIrrTable;
