import { useQuery } from '@apollo/client';
import SpinnerContainer from 'common/components/SpinnerContainer';
import { QueryItemsResponse, QueryName } from 'common/models/graphql';
import { MONITORED_AMMS, MonitoredAmm, REFRESH_INTERVALS, supportIrrTable } from 'constants/constants';
import { listMonitorVaultsQuery } from 'graphql/queries/listMonitorVaults.query';
import React, { useState } from 'react';
import { AppSyncFarm, MonitorVaults } from 'common/models/monitorVaults';
import { ContentTableWrapper } from './app.style';
import SupportIrrTable from 'common/components/SupportIrrTable/SupportIrrTable';
import { listFarmsQuery } from 'graphql/queries/listFarms.query';
import ChainSelector from 'common/components/ChainSelector';

type Props = {
};

const SupportIrrContainer: React.FC<Props> = (props) => {
  const [tab, setTab] = useState<MonitoredAmm>(MONITORED_AMMS[0].amms[0]);
  const {
    loading: loadingFarms,
    error: errorLoadingFarms,
    data: dataFarms,
    networkStatus: networStatusFarms,
    refetch: refetchFarms
  } = useQuery<QueryItemsResponse<AppSyncFarm>>(listFarmsQuery, {
    fetchPolicy: 'cache-and-network',
    pollInterval: REFRESH_INTERVALS[QueryName.listFarms]
  });
  const { loading, error, data } = useQuery<QueryItemsResponse<MonitorVaults>>(listMonitorVaultsQuery, {
    fetchPolicy: 'cache-and-network',
    pollInterval: REFRESH_INTERVALS[QueryName.listMonitorVaults]
  });

  // const vaults: MonitorVaults[] = data?.listMonitorVaults?.items.filter((v) => v.name.includes('ICHI')) || [];
  let vaults: MonitorVaults[] 
    = data?.listMonitorVaults?.items
      .filter((v) => v.supportIrrTokenAmount !== null) || []
  const farms: AppSyncFarm[] = dataFarms?.listFarms.items || [];

  const table = supportIrrTable;

  if (error) {
    console.error(error);
  }

  return (
    <SpinnerContainer isLoading={loading}>
      <ChainSelector 
        handleClick={(amm: MonitoredAmm) => setTab(amm)} 
        currAmm={tab} 
      />
      <ContentTableWrapper>
        <SupportIrrTable table={table} vaults={vaults} filter={tab} farms={farms} />
      </ContentTableWrapper>
    </SpinnerContainer>
  );
};

export default SupportIrrContainer;
