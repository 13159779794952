/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable operator-linebreak */
import { MonitorVaults } from 'common/models/monitorVaults';
import { StyledTableBodyTr, StyledTd, StyledTdItem } from 'common/styles/common.styles';
import React from 'react';
import FormatNumber from '../FormatPercent/FormatNumber';
import { StyledMonitorVaultsCoinName, StyledMonitorVaultsSubTextOne } from './monitorVaultsTable.style';

type Props = {
  vault: MonitorVaults;
};

const SupportIrrTablePosition: React.FC<Props> = (props) => {


  return (
    <StyledTableBodyTr key={props.vault.name}  className="table-no-border-bottom">
      {/* ---------------------- */}
      {/* Name */}
      {/* ---------------------- */}
      <StyledTd>
        <StyledTdItem className="primary-text-color">
          <StyledMonitorVaultsCoinName className="primary-text-color">
            <span>{props.vault.displayName}</span>
          </StyledMonitorVaultsCoinName>
        </StyledTdItem>
      </StyledTd>
      {props.vault.supportIrrTokenAmount.map((record) => (
        <StyledTd key={`${props.vault.name}_${record.irrPercent}`}>
          <StyledMonitorVaultsSubTextOne className="primary-text-color pb-3">
            <FormatNumber value={record.amount} />
          </StyledMonitorVaultsSubTextOne>
        </StyledTd>

      ))}
    </StyledTableBodyTr>
  );
};

export default SupportIrrTablePosition;
